export const SNOWPACK_PUBLIC_VERSION = "webdev-6829-topnav-keyboard-nav---a0379ec0";
export const SNOWPACK_PUBLIC_TESTING = "false";
export const SNOWPACK_PUBLIC_ENVIRONMENT = "dev";
export const SNOWPACK_PUBLIC_ARCHIVE_NAV_URL = "";
export const SNOWPACK_PUBLIC_ARCHIVE_API_URL = "archive.org";
export const SNOWPACK_PUBLIC_WAYBACK_URL = "web.archive.org";
export const SNOWPACK_PUBLIC_WAYBACK_SEARCH_URL = "https://web.archive.org/web/*/";
export const SNOWPACK_PUBLIC_TV_SEARCH_URL = "https://archive.org/tv";
export const SNOWPACK_PUBLIC_RADIO_SEARCH_URL = "https://archive.org/search.php";
export const SNOWPACK_PUBLIC_ARCHIVE_IT_COLLECTION_URL = "https://archive-it.org/collections/";
export const SNOWPACK_PUBLIC_ARCHIVE_IT_PARTNER_URL = "https://archive-it.org/organizations/";
export const SNOWPACK_PUBLIC_USER_LISTS_API_URL = "https://archive.org";
export const SNOWPACK_PUBLIC_ITEM_MANAGEMENT_URL = "https://archive.org/manage/";
export const SNOWPACK_PUBLIC_WEB_ARCHIVES_SERVICE_URL = "https://archive.org/services/web-archives/service.php";
export const SNOWPACK_PUBLIC_BASE_PATH = "/offshoot_assets/";
export const SNOWPACK_PUBLIC_ARCHIVE_IMAGE_URL = "https://archive.org";
export const SNOWPACK_PUBLIC_SUPPRESS_FACETS = "false";
export const SNOWPACK_PUBLIC_SENTRY_DSN = "https://f36cc7e0e3484c2ab9aa70f5999ee9ca@sentry.archive.org/2";
export const SNOWPACK_PUBLIC_SENTRY_TRACES_SAMPLE_RATE = "1.0";
export const SNOWPACK_PUBLIC_SENTRY_ERROR_SAMPLE_RATE = "1.0";
export const SNOWPACK_PUBLIC_ZENDESK_HELP_WIDGET_KEY = "685f6dc4-48c5-411f-8463-cc6dd50abe2d";
export const SNOWPACK_PUBLIC_BRAINTREE_AUTH_TOKEN = "sandbox_pgvymxzg_qjt7jy769mbr3t9h";
export const SNOWPACK_PUBLIC_RECAPTCHA_SITE_KEY = "6Ld64a8UAAAAAGbDwi1927ztGNw7YABQ-dqzvTN2";
export const SNOWPACK_PUBLIC_VENMO_PROFILE_ID = "1953896702662410263";
export const SNOWPACK_PUBLIC_GOOGLEPAY_MERCHANT_ID = "";
export const SNOWPACK_PUBLIC_FEATURE_FEEDBACK_SERVICE_URL = "https://archive.org/services/feature-feedback";
export const MODE = "production";
export const NODE_ENV = "production";
export const SSR = false;
